//see https://invertase.io/blog/firebase-with-gatsby
import firebase from 'firebase/app';
import 'firebase/auth'; // importing the auth module as an example
import { useEffect, useState } from 'react';
import firebaseRef from 'firebase/app';

// Firebase web config
export const PROJECT_ID = 'recipetotable-2f23c'; //got from google actions console

export const PERSIST_SESSION = firebase.auth.Auth.Persistence.SESSION;

//Below is protected
//Google APIs > Browser Key - all clients are INTENDED to have this connection information
//However, if someone tries to 'replicate' this site, they will not be able to login ANY user
//and will not be able to generate Access Tokens (prevent phishing)
//We Restrict this API key to only allow forkmore.com access/use of this info
//  Started to setup remote host server / cloud function to 'login/generate user access tokens' but 
//  it seems standard to allow web clients the below apikey, and we restrict usage to only our website
const config = {
  apiKey: "AIzaSyBueHcZNyYmdD57VZt58rrk-9xu58sBAuI",
  authDomain: "recipetotable-2f23c.firebaseapp.com", 
  databaseURL: "https://recipetotable-2f23c.firebaseio.com",
  projectId: PROJECT_ID,
  storageBucket: "recipetotable-2f23c.appspot.com",
  messagingSenderId: "1095560526018",
  appId: "1:1095560526018:web:073d5759b4e32d0706235b",
  measurementId: "G-YH9DRT6PJL"

};


let instance = null;

function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    //initializeApp with no config will allow some 'uses' but cannot generate an Access Token
    //instance = firebase.initializeApp(); //not loaded, do nothing - error on apiKey 
    instance = firebase.initializeApp(config);  
    //console.log('remoteConfig: ', JSON.stringify(firebase.remoteConfig));
    //console.log('app options: ', JSON.stringify(instance.options) );
    //console.log('check instance: ', instance.auth());
    return instance;
  }

  return null;
}


//Access Firebase From a Component
export default function useFirebase() {
  const [instance, setInstance] = useState(null);
    
  useEffect(() => {
    setInstance(getFirebase());
  }, []);

  return instance;
} 