/*
      During integration with Google Assistant we needed an OAuth2 login from mobile phone - so here it is
      So this login.js initially came from that effort
      Be advised - we used https://developers.google.com/oauthplayground/ to confirm this webpage was working correctly
      Paste into url to test (*contains clientid, but safe to put here since we restrict redirect origins)
      https://developers.google.com/oauthplayground/#step1&scopes=email&url=https%3A%2F%2F&content_type=application%2Fjson&http_method=GET&useDefaultOauthCred=unchecked&oauthEndpointSelect=Custom&oauthAuthEndpointValue=https%3A%2F%2Fforkmore.com%2Flogin&oauthClientId=44406627926-g6hvoli4vo86fokd63e3s21dphr1edtd.apps.googleusercontent.com&includeCredentials=checked&accessTokenType=query&autoRefreshToken=unchecked&accessType=offline&prompt=consent&response_type=token&wrapLines=on
      
      Example:
       Ask Google Assistant: <app action that requires login> , should take user to this website (confired in Google Actions Console > Account Linking - if user is not linked yet)
       When a user logs into this page (Firestore Authentication) we hear it - get the Access Token, and pass it back to Google Assistant with a web redirect window.location.replace (during the Account Linking process)
      *In future should also allow users to login to a 'web' client: would also use this web page to login
 */
import React, { useEffect, useState } from "react";
import MyLayout from "../components/layout";
import SEO from "../components/seo";
import useFirebase, { PROJECT_ID, PERSIST_SESSION } from "../components/firebaseComponent";

//query-string used for getting Parameters Out of URL that user sent in
//Needed when we read url passed in from google for OAuth2 (from Google Assistant, to link/log user in to firebase auth)
import queryString from "query-string";
import { Location } from "@reach/router";
//Sentry used to log errors on clients, only intended to be used to debug Google Assitant OAuth2 (/login web page)
import * as Sentry from "@sentry/react";
import { Form, Input, Button, Checkbox, Row, Col, Divider } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

//user needs to be authenticated, and this value needs to be set to return proper sucessURL to google
//Originates from passing Username/Pass to Firestore Authentication, on sucess it becomes valued
let TOKEN = null;
//Google will set this, and we forward it (unchanged) to the success url for Google to see
let STATE_STRING = null;
//At first thought google wanted us to redirect all to 'https://oauth-redirect.googleusercontent.com'
//Now believe the Request contains the Redirect_URI which may vary
//oauthplayground has different redirect_URI it wants to talk back to, so we must read it from the request params
let REDIRECT_URI = null;

//from Google Cloud Platform containing the client id we're logging in with
//https://console.cloud.google.com/
//we are intentionally using 'mealapp' client, instead of 'recipetotable' could update in future
//CANNOT CHANGE Actions On Google project to 'recipetotable' project
//Actions on Google project - during creation determines some things you can do on Actions on Google...
//'recipetotable' project was created with 'DialogeFlow' as the initializer - which trashed that Actiosn on Google Project
//'mealApp' must be used because we initialized this projects within 'Actions on Google' and subsequently have 'Scenes' etc that we NEED 
// thank you gooogle - for being so dumb (you cannot modify 'recipetotable' project and now we have 2 seperate 'billable' projects because of the way google initalized)
//export const PROJECT_ID = 'recipetotable-2f23c'; //there is a recipetotable project in cloud but not used
const GCP_PROJECT_ID = "mealapp-9edac";

//A function so we can evaluate it later
let tellGoogleSuccessURL = BASE_URL => {
  if (!BASE_URL) {
    //will be in here if Value is undefined || null
    console.log("BASE_URL must be defined: ", BASE_URL);
    //on failures we should redirect back to google anyway, but with the failure so Google Knows AccountLink Failed
    //There is an online doc on what the proper 'return due to user failed login' for Google Action + OAuth2 but have to look it up
    return;
  }

  if (!GCP_PROJECT_ID) {
    console.log("GCP_PROJECT_ID must be defined: ", GCP_PROJECT_ID);
    return;
  }

  if (!TOKEN) {
    console.log("TOKEN must be defined: ", TOKEN);
    return;
  }

  //in oauthplayground state is undefined, guess they don't send it (we do not check if undefined on purpose)
  const googleRedirectUrlStr = `${BASE_URL}#access_token=${TOKEN}&token_type=bearer&state=${STATE_STRING}`;
  console.log(`googleRedirectUrlStr: ${googleRedirectUrlStr}`);
  return googleRedirectUrlStr;
};

//could move to seperate component - login form
const LoginForm = props => {
  let [errorText, setErrorText] = useState();

  console.log("LoginForm props: ", props);

  //similar to <form> 'action' prop
  //executes when user presses 'login' button on the form
  const onFinish = values => {
    console.log("onFinish values of form: ", values);

    if (!props.firebase) {
      console.log("firebase is not loaded, do nothing");
      return;
    }

    console.log("firebase is loaded: ", props.firebase);

    let { username: email, password } = values;

    //on mobile login, it sometimes give an extra space at the end - trim that off
    email = email.trim();

    console.log("onFinish props: ", props);
    props.firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        //This is an error with logging into Firebase.Authentication module (user provided bad username/password. Network/System errors)
        let errTxt = `Error: errorCode: ${errorCode}, message: ${errorMessage}`;

        console.log(errTxt);

        //set the <p> tag to show the error
        setErrorText(errTxt);
      });

    //else we should 'see' user logs in and call appropriate listener
    //should automatically call: firebase.auth().onAuthStateChanged()
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    console.log("onFinishFailed values of form: ", values);
    console.log("onFinishFailed errorFields: ", errorFields);
    console.log("onFinishFailed outOfDate: ", outOfDate);
  };
  return (
    <Row justify="center" align="top">
      <Col span={24}>
        <div
          //Vertical Space above form
          style={{ height: 150 }}
        />

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              //below not needed, but 'user' icon looks nice
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            {/*future, add this use RN firebase code to help
            <a className="login-form-forgot" href="">
              Forgot password
            </a>
            */}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              //on user press, this will call onFinish() because htmlType='submit'
            >
              Log in
            </Button>
            {/*Or <a href="">register</a>*/}
          </Form.Item>

          {console.log("check errorText: ", errorText)}
          {errorText && <p>{errorText}</p>}
        </Form>
      </Col>
    </Row>
  );
};

const LoginPage = props => {
  //By initializing Sentry we will know if there are any errors on this page, from any web client
  //We did this during debugging, but may not need it in the future (5k events/mo free tier)
  Sentry.init({
    dsn: "https://1e9a8d6e6881474b9c61e14b00d9569e@o418847.ingest.sentry.io/5324566",
  });

  let localFirebase = null;
  localFirebase = useFirebase(); //initialize the connection to firebase auth

  //set state
  //In the future may not want to wrap every component
  //But instead wrap higher order component
  //so all child components are aware if user is logged in or not
  //for now we just need a static URL to log user in (for Google Assistant) and immediately do a redirect to Google Assistant (OAuth Account Linking process)
  useEffect(() => {

    try{
      //this works in a dev environment, but will throw error in Prod (access denied)
    console.log("useEffect firebaseState check localFirebase 1: ", localFirebase);
    } catch (e){

      console.log('localFirebase e: ', e);
    }

    if (!localFirebase) return;
    //console.log("useEffect firebaseState check localFirebase 2: ", localFirebase);

    var user = localFirebase.auth().currentUser;
    console.log("check if user: ", user);

    ////ONLY DO THIS FOR TESTING PURPOSES - REMOVE THE PERSISTENCE BELOW!
    //We are requiring the user to sign- in every time to DEBUG the Account Linking Process only
    //If you remove the .setPersistence() wrapped function, then user will 'remain logged in by default
    //as long as Browser cache holds logged-in credentials' I believe (depends on how Firebase/Browser handles it)
    console.log("check 1: ", PERSIST_SESSION);

    localFirebase
      .auth()
      .setPersistence(PERSIST_SESSION)
      .then(function() {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // New sign-in will be persisted with session persistence.

        // Setup the onAuthStateChanged observer for when user logs in
        // return firebase.auth().signInWithEmailAndPassword(email, password);
        // for some reason useEffect() Is EXPECTING a return something, not sure why.. so return firebaseState?
        return localFirebase.auth().onAuthStateChanged(user => {
          //console.log("LoginPage firebase auth User:", user); //returns [Object object]
          console.log("LoginPage firebase auth User: ", JSON.stringify(user)); //stringify is better output

          if (user) {
            // User is signed in.

            //https://firebase.google.com/docs/auth/admin/manage-sessions
            //Apprently every user has 2 'tokens': 
              //Firebase ID token (a JWT): Lasts 1 hour - every time it is requested
              //Refresh token: Long-lived: should not expire except when: User Deleted/Disabled/<Major_Events> like Passowrd/email updates
                //use Refresh Tokens to retrieve more 'Firebase ID tokens'          
        
           /*
           using the refreshToken
             - we could NOT decode the email from on fulfillment/
           */ 
           //const { refreshToken, email } = user;
           //console.log("refreshToken retrieved: ", refreshToken);
            
           /*
           using the accessToken 
           - THIS RETURNS NOTHING, confusingly you cannot do user.accessToken
              + This 'accessToken' prop seen in stringify(user) IS EQUIVALENT to user.getIdToken()
              + we CAN decode the email from on fulfillment/
              - then it expires 1 hour later
           */  
           //const { accessToken } = user; //will not get the accessToken Property, use user.getIdToken() instead
 
            /*
            using the idToken
              I am unclear if .getIdToken() returns user.idToken || user.accessToken or sometimes generates a new one??
              + seems equivalent to stringify(user) 'accessToken' property, although you cannot get it directly (above)
              - this is the short lived FirebaseIDToken/JWT, 1 hour
              + this CAN be decoded by fulfillment/ (but expires..)
            */
           user.getIdToken().then(function(idToken) {
            console.log("idToken retrieved: ", idToken);
             
             //on a whim - setting to the idToken, just need to confirm after account linking 
             //+1 hour Assistant can still call server side function /fulfillment AND decode this
               //think some magic happens where google re-issues an accessToken? or do I need /token endpoint as part of OAuth setup?
             TOKEN = idToken;
                    
             // Simulate a mouse click:
             // window.location.href = "http://www.w3schools.com";
 
             // Simulate an HTTP redirect:
             // only difference is user will not be able to click 'back' button in browser
             let destination = tellGoogleSuccessURL(REDIRECT_URI);
             if (destination) {
                // Send token to Google backend via HTTPS
               window.location.replace(destination);
             } else {
               console.log("bad destination");
             }
          });

          } else {
            // User is signed out.
            TOKEN = null;
          }

          //end onAuthStateChanged
        });
        //end setPersistence
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        let errTxt = `Error: errorCode: ${errorCode}, message: ${errorMessage}`;
      });

    //"[localFirebase]" means call useEffect() whenever localFirebase changes
  }, [localFirebase]);

  return (
    <MyLayout>
      {console.log("Gatsby loaded LoginPage props:  ", props)}
      <SEO title="Login" />

      {/*
      Now we need to handle parameterized inputs
      use npm query-string @reach/router
      Google Assistant Account Linking send in this request:
      GET https://myservice.example.com/auth?client_id=GOOGLE_CLIENT_ID&redirect_uri=REDIRECT_URI&state=STATE_STRING&response_type=token

      and on user login success respond to Google Assistant with the Access Token (from Auth - Firestore Authorization)
      https://oauth-redirect.googleusercontent.com/r/YOUR_PROJECT_ID#access_token=TOKEN&token_type=bearer&state=STATE_STRING

      <Location> is used to extract the parameters passed into the URL
      example: <site>/login?idk=1
               location.search = { idk: 1}  (An object with 1 property 'idk' with a value of '1')
       This will be used further to parse google parameters:
       client_id      (TODO: used for verification)
       redirect_uri
       state          (Google uses this for it's own internal verification, it should not be modified)
       //response_type=token
       */}

      <Location>
        {/*Why do I need a <Location> component for this, isn't there a general method to call?*/}
        {({ location, navigate }) => {
          //below will print any aditional URL paremeters passed when User/Google assistant attempted to access the /login page
          let urlParamObj = queryString.parse(location.search);
          console.log("location search: ", urlParamObj);
          //Params from Google Assistant OAuth2 Account Linking Description:
          //state: comes from google, they check that this value does not change - required in redirect
          //other params:
          //access_type: 'offline'
          //client_id: seen in google cloud api, as the OAuth2 Client 'My Google Assistant Client' id
          //redirect_uri: where to RETURN the user to after login
          //https://developers.google.com/oauthplayground/ - will try to redirect back to the playground
          //actual assistant may try to pass another url? - expected: oauth-redirect.googleusercontent.com
          //unknown what the 'actual' value is
          //response_type: 'token' (either token or authorization code, but we expect token)
          //scope: 'profile' (may be many scopes and may include 'email, profile, openid' )
          let { state, redirect_uri } = urlParamObj;
          STATE_STRING = state;
          REDIRECT_URI = redirect_uri;
        }}
      </Location>

      {/*
        Begin login buttons
        {console.log('Check LoginPage firebaseState: ', firebaseState)}
        {console.log('Check LoginPage firebaseState.firebaseStateProp: ', firebaseState.firebaseStateProp)}
      */}

      <LoginForm firebase={localFirebase} />
    </MyLayout>
  );
};

export default LoginPage;
